<template>
  <div class="BetweenLineEchart">
    <div :id="id" class="echarts"></div>
  </div>
</template>
<script>
import echartsMixins from "@/assets/js/echartsMixins.js"

export default {
  props: ['id', 'ehartData'],
  mixins: [echartsMixins],

  data() {
    return {
      myChart: '',
    }
  },
  watch: {
    ehartData: {
      handler(newVal, oldVal) {
        this.initEcharts()
      },
      deep: true,
    },
  },
  mounted() {
    this.myChart = this.$echarts.init(document.getElementById(this.id))
    this.initEcharts()
  },
  methods: {
    // 刷新组件
    handlerResize(){
      this.myChart.resize()
    },
    initEcharts() {
      var option = {
        color: ['#3398DB'],
        // title: {
        //   text: 'z分数图',
        //   textStyle: {
        //     fontSize: '16',
        //     fontWeight: 'bold',
        //   },
        // },
        //  在坐标轴上显示数据
        label: {
          normal: {
            show: true,
            textStyle: {
              color: '#d27d39',
              fontSize: '18',
            },
            position: 'top',
          },
        },
        tooltip: {
          show: true,
          position: 'top',
          trigger: 'axis', //axis
          axisPointer: {
            type: 'line', // 默认为直线，可选为：'line' | 'shadow'
          },
          formatter: '{a}: {c}',
        },
        legend: {
          show: true,
          type: 'plain',
          top: '1%',
          right: '5%',
          itemGap: 50, // 间隔距离
          data: ['本次质评'],
        },
        grid: {
          top: '15%',
          left: '1%',
          right: '3%',
          bottom: '40%',
          containLabel: true,
        },
        xAxis: [
          {
            type: 'value',

            axisTick: {
              show: true,
              inside: true,
            },
            boundaryGap: true,
            // 控制网格线是否显示
            splitLine: {
              show: false,
            },
            //  改变x轴颜色
            axisLine: {
              lineStyle: {
                width: 2,
                color: '#DCE0E8',
              },
            },
            //  改变x轴字体颜色和大小
            axisLabel: {
              margin:-70, 
              // align: 'center',
              textStyle: {
                color: '#9AA1A9', 
                fontSize: '14',
              },
              interval: 'auto',
            },
          },
        ],
        yAxis: [
          {
            type: 'value',
            min: -3,
            max: 3,
            textStyle: {
              color: '#9AA1A9FF',
              fontSize: '14',
            },
            //  隐藏y轴
            axisLine: {
              show: false,
            },
            // 去除y轴上的刻度线
            axisTick: {
              show: false,
            },
            // 控制网格线是否显示
            splitLine: {
              show: true,
              lineStyle: {
                color: ['#DCE0E8'],
                type: 'dashed',
              },
            },
            //  改变y轴字体颜色和大小
            axisLabel: {
              textStyle: {
                color: ['#999999'],
                fontSize: '14',
              },
              formatter: function (value, index) {
                return value
              },
            },
          },
        ],
        series: [
          {
            name: '本次质评',
            type: 'scatter',
            symbolSize: 7,
            itemStyle: {
              color: '#F1734E',
              shadowBlur: 15,
              shadowColor: 'rgba(241,115,78,0.5)',
              shadowOffsetY: 0,
            },
            data: this.ehartData.y,
          },
        ],
      }
      this.myChart.setOption(option)
    },
  },
}
</script>
<style lang="scss" scoped>
.BetweenLineEchart {
  width: 100%;
  position: relative;
  .echarts {
    width: 100%;
    height: px(416);
  }
}
</style>
