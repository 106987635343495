<template>
  <div>
    C1:
    <el-table
      :data="now_detailsData.c1"
      style="width: 100%">
      <el-table-column
        prop="value"
        label="值"
        width="80">
        <template slot-scope="scope">
          <span class="weightFont">{{ scope.row.value?scope.row.value.toFixed(2):'-' }}</span>
        </template>
      </el-table-column>
       <el-table-column
        prop="normalized"
        label="偏差"
        width="80">
        <template slot-scope="scope">
          <span class="weightFont">{{ scope.row.normalized?scope.row.normalized.toFixed(2):'-' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="是否在控" align="center" width="140">
        <template slot-scope="scope">
          <div class="editBox">
            <span :class="scope.row.lc?'shikongColor':''">
              {{ !scope.row.value?'':scope.row.lc ? "失控" : "在控" }}
            </span>
            <!-- <p
              class="editStyle"
              @click="toControlReport(scope.row,1)"
              v-if="scope.row.lc"
            >
              填写
            </p> -->
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="rule"
        align="center"
        label="违背规则">
      </el-table-column>
      <el-table-column
        prop="time"
        align="center"
        label="时间">
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <div class="caozuo">
            <el-checkbox v-model='scope.row.checked' style="margin-right:20px" v-show="!splitFun_disable"></el-checkbox>
            <!-- <el-popconfirm
              confirm-button-text='确定'
              cancel-button-text='取消'
              icon="el-icon-info"
              icon-color="red"
              @confirm="delete_qc_pointFun(scope.row.id,scope.row.qc_instance_id)"
              title="确定删除该质控点吗？"
            >
              <el-button slot="reference" icon="el-icon-delete" type="danger" circle ></el-button>
            </el-popconfirm> -->
            <el-button :type="scope.row.isShow?'success':'info'" size="mini" @click=changeShow(1,scope.row)>{{scope.row.isShow?'当前':'切换'}}</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    C2:
    <el-table
      :data="now_detailsData.c2"
      style="width: 100%">
      <el-table-column
        prop="value"
        label="值"
        width="80">
        <template slot-scope="scope">
          <span class="weightFont">{{ scope.row.value?scope.row.value.toFixed(2):'-' }}</span>
        </template>
      </el-table-column>
       <el-table-column
        prop="normalized"
        label="偏差"
        width="80">
        <template slot-scope="scope">
          <span class="weightFont">{{ scope.row.normalized?scope.row.normalized.toFixed(2):'-' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="是否在控" align="center" width="140">
        <template slot-scope="scope">
          <div class="editBox">
            <span :class="scope.row.lc?'shikongColor':''">
              {{ !scope.row.value?'':scope.row.lc ? "失控" : "在控" }}
            </span>
            <!-- <p
              class="editStyle"
              @click="toControlReport(scope.row,2)"
              v-if="scope.row.lc">
              填写
            </p> -->
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="rule"
        align="center"
        label="违背规则">
      </el-table-column>
      <el-table-column
        prop="time"
        align="center"
        label="时间">
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <div class="caozuo">
            <el-checkbox v-model='scope.row.checked' style="margin-right:20px" v-show="!splitFun_disable"></el-checkbox>
            <!-- <el-popconfirm
              confirm-button-text='确认'
              cancel-button-text='取消'
              icon="el-icon-info"
              icon-color="red"
              @confirm="delete_qc_pointFun(scope.row.id,scope.row.qc_instance_id)"
              title="确定删除该质控点吗？"
            >
              <el-button slot="reference" icon="el-icon-delete" type="danger" circle ></el-button>
            </el-popconfirm> -->
            <el-button :type="scope.row.isShow?'success':'info'"  size="mini" @click=changeShow(2,scope.row)>{{scope.row.isShow?'当前':'切换'}}</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>

  </div>
</template>

<script>
export default {
  props: ['detailsData','splitFun_disable'],
  data() {
    return {
      now_detailsData: "",
    }
  },
  created() {
    this.now_detailsData = this.detailsData
  },

  methods: {
    changeShow(index,row) {
      this.now_detailsData['c'+index] = this.now_detailsData['c'+index].map(temp => {temp.isShow = false; return temp});
      row.isShow = true;
    },
  },
}
</script>

<style lang="scss" scoped>

</style>>
