<template>
  <div class="data_info_list">
    <!-- <div class="top">
      <ul>
        <li>是否查看全部:</li>
        <li>
          <el-switch v-model="is_all" active-text="是" inactive-text="否"
            @change="change">
          </el-switch>
        </li>
        <li>是否被计算:</li>
        <li>
          <el-switch v-model="is_compute" active-text="是" inactive-text="否" :disabled="is_all"
            @change="change">
          </el-switch>
        </li>
        <li>是否被接受:</li>
        <li>
          <el-switch v-model="is_accept" active-text="是" inactive-text="否" :disabled="is_all"
            @change="change">
          </el-switch>
        </li>
        <li>结果范围:</li>
        <li>
          大于
          <el-input v-model="minValue" placeholder="请输入内容" :disabled="is_all" @change="change"></el-input>
        </li>
        <li>
          小于
          <el-input v-model="maxValue" placeholder="请输入内容" :disabled="is_all" @change="change"></el-input>
        </li>
      </ul>
    </div> -->

    <el-table :data="now_data"  style="width: 100% height:100%" height="550" :row-class-name="tableRowClassName">
      <el-table-column label="序号" align="center" width="80">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
          <!-- {{ serialtext(scope.$index+1)  }} -->
        </template>
      </el-table-column>
      <el-table-column label="医院名称" align="center" width="">
        <template slot-scope="scope">
          {{ scope.row.hospital_full_name }}
        </template>
      </el-table-column>
      <el-table-column label="机号" align="center" :width="flexColumnWidth(120)">
        <template slot-scope="scope">
         {{ scope.row.instrument_id }}
        </template>
      </el-table-column>
      <el-table-column label="时间" align="center" :width="flexColumnWidth(200)">
        <template slot-scope="scope">
         {{ scope.row.c1_time||scope.row.c2_time }}
        </template>
      </el-table-column>
      <!-- <el-table-column label="是否接受" align="center" :width="flexColumnWidth(100)">
        <template slot-scope="scope">
         {{ scope.row.c1_accept== true?'是': scope.row.c1_accept==false
                                      ?'否':scope.row.c2_accept== true
                                      ?'是':scope.row.c2_accept== false
                                      ?'否':''
              }}

        </template>
      </el-table-column> -->
      <!-- <el-table-column label="是否被计算" align="center" :width="flexColumnWidth(140)">
        <template slot-scope="scope">
         {{ scope.row.is_compute?'是':'否' }}
        </template>
      </el-table-column> -->
      <el-table-column label="结果" align="center" :width="flexColumnWidth(120)">
        <template slot-scope="scope">
         {{ scope.row.c1||scope.row.c2 }}mg/L
        </template>
      </el-table-column>
      <!-- <el-table-column label="该点来源" align="center" :width="flexColumnWidth(100)">
        <template slot-scope="scope">
          <div class="caozuo">
            <div class="box" @click="open_qcInstance(scope.row)">
              <img src="@/assets/img/fhd.png" alt="" />
            </div>
          </div>
        </template>
      </el-table-column> -->
    </el-table>

    <!-- 数据详情弹窗 -->
    <el-dialog
      title="数据详情"
      :visible.sync="detailsStatus"
      :close-on-click-modal="false"
      append-to-body
      width="60%">
      <div class="reportBox">
        <!-- <data_details ref="data_details" v-if="detailsStatus" :detailsData="detailsData"></data_details > -->
          C1:
          <el-table
            :data="detailsData.c1"
            style="width: 100%">
            <el-table-column
              prop="value"
              label="值"
              width="80">
              <template slot-scope="scope">
                <span class="weightFont">{{ scope.row.value?scope.row.value.toFixed(2):'-' }}</span>
              </template>
            </el-table-column>
            <el-table-column label="是否在控" align="center" width="140">
              <template slot-scope="scope">
                <div class="editBox">
                  <span :class="scope.row.lc?'shikongColor':''">
                    {{ scope.row.value?scope.row.lc?"失控":"在控":'' }}
                  </span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="rule"
              align="center"
              label="违背规则">
            </el-table-column>
            <el-table-column
              prop="time"
              align="center"
              label="时间">
            </el-table-column>
          </el-table>
          C2:
          <el-table
            :data="detailsData.c2"
            style="width: 100%">
            <el-table-column
              prop="value"
              label="值"
              width="80">
              <template slot-scope="scope">
                <span class="weightFont">{{ scope.row.value?scope.row.value.toFixed(2):'-' }}</span>
              </template>
            </el-table-column>
            <el-table-column label="是否在控" align="center" width="140">
              <template slot-scope="scope">
                <div class="editBox">
                  <span :class="scope.row.lc?'shikongColor':''">
                    {{ scope.row.value?scope.row.lc?"失控":"在控":'' }}
                  </span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="rule"
              align="center"
              label="违背规则">
            </el-table-column>
            <el-table-column
              prop="time"
              align="center"
              label="时间">
            </el-table-column>
          </el-table>

      </div>
    
    </el-dialog>
  </div>
</template>

<script>
import api from '@/api/quality'
import {flexColumnWidth} from '@/assets/js/adaption.js'
import data_details from './data_details.vue'


export default {
  props: ['C_data'],
  components: {data_details},
  data() {
    return {
      is_accept: true,
      is_compute: true,
      is_all: true,
      now_data: [],
      maxValue: 200,
      minValue: 0,
      detailsStatus: false,
      detailsData: [],
    }
  },
  created() {
    this.now_data = this.C_data;
  },
  methods: {
    flexColumnWidth(width) {
      return flexColumnWidth(width)
    },
    // 高亮
    tableRowClassName({ row, rowIndex }) {
      return row.is_compute? '':'danger-row'
    },
    // 查看来源
    open_qcInstance(row) {
      const loading = this.$loading({
        lock: true,
        text: '正在搜索',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      api.search_qc_instance({qc_instance_id:row.qc_instance_id}).then(res=>{
        if(res.code ==200){
          let data = res.data
          if(data == null){
            this.$message.warning('未找到数据')
          }
          let c1data = {
            id: data.c1_id,
            value: data.c1,
            lc: data.is_c1_lc,
            rule: data.c1_rule,
            normalized: data.normalized_c1,
            time: data.c1_time,
            qc_instance_id: data.qc_instance_id,
            checked: false,
            isShow:true,
          }
          let c2data = {
            id: data.c2_id,
            value: data.c2,
            lc: data.is_c2_lc,
            rule: data.c1_rule,
            normalized: data.normalized_c1,
            time: data.c2_time,
            qc_instance_id: data.qc_instance_id,
            checked: false,
            isShow:true,
          }
          this.detailsData.c1 = [c1data]
          this.detailsData.c2 = [c2data]
          data.remain_level.forEach((e,index) => {
            let cData = {
              id: data.remain_point_id[index],
              value: data.remain_value[index],
              // lc: data.is_remain_lc[index],
              // rule: data.remain_rule[index],
              normalized: data.normalized_remain[index],
              time: data.remain_point_time[index],
              qc_instance_id: data.qc_instance_id,
              isShow: false,
              checked: false,
            }
            this.detailsData['c'+e].push(cData)
          })

          this.detailsData.date_time_order = data.date_time_order
          this.detailsData.qc_instance_id = data.qc_instance_id
          this.detailsData.qc_serum_id = data.qc_serum_id
          this.detailsStatus = true;
        }
      }).finally(msg => {
        loading.close()
      })
    },
    // 改变后筛选
    change() {
      if(this.is_all){
        this.now_data = this.C_data;
      }else {
        this.now_data = this.C_data.filter(e => {
          return (e.c1_accept===this.is_accept||e.c2_accept === this.is_accept)
            &&(e.is_compute===this.is_compute)
            &&(e.c1>=this.minValue||e.c2>=this.minValue)
            &&(e.c1<=this.maxValue||e.c2<=this.maxValue)
        })
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.data_info_list {
}
.el-table {
  ::v-deep .warning-row {
    background: #e6a23c;
  }
  ::v-deep .danger-row {
    background: #FAECD8;
  }
}
.caozuo {
  display: flex;
  justify-content: center;
  align-items: center;

  .box {
    img {
      width: px(36);
      height: px(36);
      cursor: pointer;
    }
  }
  
}
.top {
  ul {
    display: flex;
    justify-content: start;
    align-items: center;
    li {
      margin-left: px(30);
    }
  }
}
</style>